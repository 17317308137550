import * as Yup from 'yup'
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import Ajax from "../Ajax";
import { useParams } from "react-router-dom";
import React from 'react';

export default function LoginPage () {

    const params = useParams()
    const [loading, setLoading] = React.useState(false)

	var formik = useFormik({
        initialValues: {
            accessCode: ""
        },
        validationSchema: Yup.object({
            accessCode: Yup.string().required("This field is required")
        }),
        onSubmit: async (values) => {
            setLoading(true)
            
            await Ajax.Public.Login(params.questionnaireUid, values.accessCode).then((response) => {
                console.log("Settings code", response)
                window.localStorage.setItem("aora_questionnaire_jwt", response.token)
                window.location.reload()
            }).catch((response) => {
                formik.setErrors({accessCode: response.detail ? response.detail : "There was an error"})
                setLoading(false)
            })
        }
    })

	return (
		<div>
			<h1 style={{fontSize: "50px"}}>Sign in</h1>

            <p>To access your questionnaire you must enter your access code provided to you by your advisor.</p>

            <form onSubmit={formik.handleSubmit} style={{maxWidth: "400px", display: "grid"}}>
                <div>
                    <TextField 
                        label="Enter access code" 
                        name="accessCode"
                        autoFocus
                        value={formik.values.accessCode} onChange={formik.handleChange} />
                    
                    {formik.errors.accessCode ? <div className="warning">{formik.errors.accessCode}</div> : null}
                </div>
                
                <br/>
                
                <Button 
                    type="submit"
                    disabled={loading || (formik.submitCount && !formik.isValid)}
                    variant="contained" 
                    color="success"
                    className="btn btn-md">
                    Sign in
                </Button>
            </form>
		</div>
	)
}