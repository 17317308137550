import React from 'react'
import * as Yup from 'yup'
import { Context } from '../context'
import { Button } from '@mui/material'
import { useFormik } from 'formik'
import Field from './Field.tsx'
import ErrorBoundary from '../misc/ErrorBoundary.jsx'
import { compareAsc, format, isValid, parse } from 'date-fns'


export default function FieldsForm ({
    fields, 
    initialValues = {},
    onSubmit = (values) => {},
    maxHeight = "none",
    submitButtonText = "Submit"
}) {

    const context = React.useContext(Context)

    var yupObject = {}
    
    fields.forEach(field => {
        
        // Set intial value to guess
        if (!(field.name in initialValues)) {
            if (field.guess) {
                initialValues[field.name] = field.guess
            } else if (field.type === "checkbox") {
                initialValues[field.name] = "false"
            } else {
                initialValues[field.name] = ""
            }
        }
        
        // Set validation
        if (field.type === "checkbox") {
            
            // yupObject[field.name] = field.required !== false 
            //     ? Yup.string().test('check', `Please confirm`, val => val === "true")
            //     : Yup.string()

        } else if (field.required !== false && field.type === "date") {
        
            yupObject[field.name] = Yup.string().test({
                name: field.name,
                // skipAbsent: true,
                test(value, ctx) {

                    var split = value?.split("/")
                    var reformattedValue = split?.reverse().join("-")
                    var date = new Date(reformattedValue)

                    if (!isValid(date)) {
                        return ctx.createError({ message: 'Enter a valid date' })
                    }

                    if (field.min) {
                        var minDate = field.min === "current_date" ? new Date() : parse(field.min, "dd/MM/yyyy", new Date())
                        if (compareAsc(date, minDate) !== 1) {
                            return ctx.createError({ message: `Enter a date after ${format(minDate, "do LLLL u")}` })
                        }
                    }

                    if (field.max) {
                        var maxDate = field.max === "current_date" ? new Date() : parse(field.max, "dd/MM/yyyy", new Date())
                        if (compareAsc(date, maxDate) !== -1) {
                            return ctx.createError({ message: `Enter a date before ${format(maxDate, "do LLLL u")}` })
                        }
                    }

                    return true
                }
            })
        } else if (field.required !== false) {
            
            var regex = field.regex ? field.regex : "^[“”‘’ -~À-Ö\p{Sc}\p{So}\p{Mn}\p{P}\p{Z}À-ÿ\w\n]*$"
            var regexMessage = field.regexMessage ? field.regexMessage : "Only use standard European characters"
            var fieldValidation = Yup.string().matches(regex, regexMessage)

            if (field.min) {
                fieldValidation = fieldValidation.test('min', `You must enter at least ${field.min} characters`, val => val?.length >= field.min)
            }
            if (field.max) {
                fieldValidation = fieldValidation.test('max', `You are limited to ${field.max} characters`, val => val?.length <= field.max)
            }
            if (field.required !== false) {
                fieldValidation = fieldValidation.required("This field is required")
            }

            yupObject[field.name] = fieldValidation
        }
    })

    var formik = useFormik({
        
        initialValues: initialValues,
        validationSchema: Yup.object(yupObject),
        
        onSubmit: (values) => {
            return onSubmit(values)
        }
    })

    const removeHiddenValues = () => {
        var displayedFieldKeys = []
        
        fields.forEach(field => {
            if (!context.isHidden(field, formik.values)) {
                displayedFieldKeys.push(field.name)
            }
        })

        fields.forEach(field => {
            if (!displayedFieldKeys.includes(field.name)) {
                // console.log("Removing", field.name)
                delete formik.values[field.name]
                delete formik.errors[field.name]
            }
        })

        return displayedFieldKeys
    }

    React.useEffect(() => {
        removeHiddenValues()
    })

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-fields" style={{maxHeight: maxHeight}}>
                {fields.map((field, i) => {
                
                    if (context.isHidden(field, formik.values)) {
                        return <React.Fragment key={field.name}></React.Fragment>
                    }

                    return (
                        <ErrorBoundary key={field.name} >
                            <Field
                                field={field} 
                                formik={formik}
                                autoFocus={i === 0} />
                        </ErrorBoundary>
                    )
                })}
            </div>

            <div className="form-buttons">
                <Button 
                    type="submit" 
                    className="btn btn-lg" 
                    color="success" 
                    disabled={formik.isSubmitting || context.state.locked}
                    variant="contained">
                    {submitButtonText}
                </Button>

                {context.state.locked ? <small>This questionnaire is locked</small> : null}
            </div>
        </form>
    )
}