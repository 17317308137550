import {
    Settings, 
    Support, 
    Menu, 
    ExitToApp, 
    School, 
    Layers, 
    Clear, 
    Home, 
    Accessibility, 
    People, 
    ArrowRight, 
    ArrowLeft, 
    FolderOpen, 
    BlurOn, 
    Balance, 
    ChevronRight, 
    ChevronLeft, 
    Done, 
    Subject, 
    DoubleArrow, 
    InfoOutlined, 
    Search, 
    Download,
    MiscellaneousServices,
    OpenInNew,
    MoreHoriz,
    WorkOutline,
    UploadFile,
    AccountCircle,
    Apartment,
    Token,
    Remove,
    Add,
    SettingsInputComponent,
    Sensors,
    ArrowUpward,
    ArrowDownward,
    ArrowCircleRight,
    ArrowCircleLeft,
    PlayArrow,
    Pause,
    Refresh,
    QuestionMark,
    BugReport,
    Edit,
    Save,
    FastForward,
    Woman,
    Man,
    TravelExplore,
    TaskAlt,
    HighlightOff,
    FiberManualRecord,
    Warning,
    Handyman,
    Business,
    Badge,
    Contacts,
    AccountBalance,
    Person,
    FilterAlt,
    Star,
    Visibility,
    VisibilityOff,
    SubdirectoryArrowRight
} from '@mui/icons-material';


export const Icons = {

    Account: Business,
    Gevernment: AccountBalance,
    Business: Business,
    Individual: Person,
    SponsorAccount: Badge,
    Client: Business,
    SubAccount: Apartment,
    User: AccountCircle,
    Automation: MiscellaneousServices,
    Folder: FolderOpen,
    Case: WorkOutline,
    Enquiry: Balance,
    Report: Subject,
    Person: Man,
    Woman: Woman,
    Man: Man,
    People: People,
    Expert: School,

    Component: SettingsInputComponent,
    Connection: Sensors,
    Download: Download,
    Settings: Settings,
    Support: Support,
    Exit: ExitToApp,
    Layers: Layers,
    Token: Token,
    Menu: Menu,
    Home: Home,
    Positive: TaskAlt,
    Negative: HighlightOff,

    TerritoryFinder: TravelExplore,
    QuestionMark: QuestionMark,
    UploadFile: UploadFile,
    Info: InfoOutlined,
    Filter: FilterAlt,
    More: MoreHoriz,
    Open: OpenInNew,
    Search: Search,
    Minus: Remove,
    Plus: Add,
    Blur: BlurOn,
    Run: PlayArrow,
    Refresh: Refresh,
    Warning: Warning,
    Bug: BugReport,
    Pause: Pause,
    Edit: Edit,
    Save: Save,
    Tick: Done,
    X: Clear,
    Star: Star,
    Show: Visibility,
    Hide: VisibilityOff,
    XCircle: Clear,
    Dot: FiberManualRecord,
    Maintenance: Handyman,
    
    FastForward: FastForward,
    ChevronRight: ChevronRight,
    ChevronLeft: ChevronLeft,
    ArrowDown: ArrowDownward,
    ArrowUp: ArrowUpward,
    DoubleArrow: DoubleArrow,
    ArrowRight: ArrowRight,
    ArrowLeft: ArrowLeft,
    ArrowCircleRight: ArrowCircleRight,
    ArrowCircleLeft: ArrowCircleLeft,
    Subdirectory: SubdirectoryArrowRight
    
}

export function TypeIcon ({node, ...props}) {
    switch (node.typeName) {
        case "Aora.Nationality.Data.SponsorNode":
            return  <Icons.SponsorAccount className="type-icon" />
        case "Aora.Platform.Data.AccountNode":
        case "Aora.Nationality.Data.SponsorAccountNode":
            if (node.parentAccount?.uid === "ACCOUNT_-1") return <Icons.Account className="type-icon" />
            // if (node.customerType === "I") return <Icons.Individual className="type-icon" />
            // if (node.customerType === "N") return <Icons.Business className="type-icon" />
            // if (node.customerType === "G") return <Icons.Gevernment className="type-icon" />
            return  <Icons.Client className="type-icon" />
        case "Aora.Platform.Data.UserNode":
            return <Icons.User className="type-icon" />
        case "Aora.Platform.Data.FolderNode":
            return <Icons.Folder className="type-icon" />
        case "Aora.Platform.Data.CaseNode":
            return <Icons.Case className="type-icon" />
        case "Aora.Nationality.Data.PersonNode":
            if (node.sex) {
                return node.sex === "F" ? <Icons.Woman className="type-icon" /> : <Icons.Man className="type-icon" />
            }
            return <Icons.Person className="type-icon" />
        case "Aora.Platform.Data.EnquiryNode":
            return <Icons.Enquiry className="type-icon" />
        case undefined:
        case null:
        default:
            return <Icons.Blur className="type-icon" />
    }
}
