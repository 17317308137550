import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Context } from "../context";
import React from "react";
import { Icons } from "../misc/Icons";

export default function DevTools () {

    const context = React.useContext(Context)
    const [rawQuestionnaireText, setRawQuestionnaireText] = React.useState("")

    const copyAnswers = () => {
        navigator.clipboard.writeText(JSON.stringify(context.state.answers, null, "\t"))
    }

    const copyQuestionnaire = () => {
        navigator.clipboard.writeText(JSON.stringify(context.state.questionnaire, null, "\t"))
    }

    if (!context.state.showDevTools) return <></>

    return (
        <div className="dev-tools">
            <h4>Developer tools</h4>

            <div style={{display: "grid", gridTemplateColumns: "70% 30%", color: "grey", fontSize: "12px"}}>
                <div>Current page</div>
                <div>{context.state.pageIndex}</div>
                <div>Total pages <span className="link" onClick={() => copyQuestionnaire()}>Copy</span></div>
                <div>{context.state.questionnaire.pages.length}</div>
                <div>Total answers <span className="link" onClick={() => copyAnswers()}>Copy</span></div>
                <div>{Object.keys(context.state.answers).length}</div>
            </div>

            <textarea
                placeholder="Paste questionnaire JSON here"
                rows={4} 
                value={rawQuestionnaireText}
                onChange={(e) => setRawQuestionnaireText(e.target.value)} />

            <Button 
                variant="contained" 
                onClick={() => context.overwriteQuestionnaire(rawQuestionnaireText)}>
                Overwrite questionnaire and reset values
            </Button>

            <PageSelector />
        </div>
    )
}


function PageSelector () {

    const context = React.useContext(Context)

    const getAllPages = () => {
        var pageList = []

        const handlePages = (pages) => {
            pages.forEach(page => {
                pageList.push(page)

                // Check for conditional pages
                page.fields?.forEach(field => {
                    var value = context.state.answers[field.name]
                    if (field.conditional_pages && value in field.conditional_pages) {
                        var conditionalPages = field.conditional_pages[value]
                        handlePages(conditionalPages)
                    }
                })
            })
        }

        handlePages(context.state.questionnaire.pages)

        return pageList
    }

    var allPages = getAllPages()

    return (
        <FormControl variant="standard">
            <InputLabel id="page-selector-label">Select a page to preview</InputLabel>
            <Select
                labelId="page-selector-label"
                id="page-selector"
                value={context.state.pageIndex}
                // autoFocus={autoFocus}
                // error={formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : false}
                onChange={(e) => context.setPreviewPage(allPages[e.target.value])}>
                    <MenuItem value={null}>
                        <b>Current page</b>
                    </MenuItem>
                    {allPages.map((page, i) => (
                        <MenuItem key={i} value={i}>
                            {i} {page.title} {page.submitted ? <Icons.Tick style={{fontSize: "14px", color: "grey"}} /> : null}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}