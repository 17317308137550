import { IQuestionnaireData } from "./interfaces"

const host = process.env.NODE_ENV === 'development' 
    ? "http://localhost:8002"
    : ""

const generateHeaders = () => {
    
    var headers = {"Content-Type": "application/json"}
    const token = window.localStorage.getItem("aora_questionnaire_jwt")

    if (token) {
        headers["Authorization"] = `Bearer ${token}`
    }

    return headers
}

const requests = {
    get: async (uri) => {
        var response = await fetch(`${host}${uri}`, {headers: generateHeaders(), credentials: "include", method: "GET"})

        if (response.status === 401) {
            window.localStorage.removeItem("aora_questionnaire_jwt")
        }

        if (!response.ok) throw await response.json()

        return response.json()
    },
    post: async (uri, data) => {
        var response = await fetch(`${host}${uri}`, {headers: generateHeaders(), credentials: "include", method: "POST", body: JSON.stringify(data)})
        
        if (response.status === 401) {
            window.localStorage.removeItem("aora_questionnaire_jwt")
        }

        if (!response.ok) throw await response.json()
        
        return await response.json()
    },
    put: async (uri, data) => {
        var response = await fetch(`${host}${uri}`, {headers: generateHeaders(), credentials: "include", method: "PUT", body: JSON.stringify(data)})
        
        if (response.status === 401) {
            window.localStorage.removeItem("aora_questionnaire_jwt")
        }

        if (!response.ok) throw await response
        
        return await response
    }
}

const Ajax = {
    Questionnaire: {
        Get: async (id) =>                                          await requests.get(`/respondent/questionnaire/${id}`),
        Update: async (data: IQuestionnaireData) =>                 await requests.put(`/respondent/questionnaire`, data),
        Submit: async (id) =>                                       await requests.put(`/respondent/questionnaire/submit/${id}`, null),
    },
    Public: {
        Login: async (questionnaireUid, accessCode) =>               await requests.post("/public/login", {uid: questionnaireUid, accessCode: accessCode}),
        GetAdvisorAccount: async (advisorUid) =>                    await requests.get(`/public/advisor/${advisorUid}`)
    }
}

export default Ajax