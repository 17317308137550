import React from "react";
import { Context } from "../context";
import { Button } from "@mui/material";
import { IField, IPage } from "../interfaces";

export default function PreviousAnswers ({forceShow = false}) {
    
    const context = React.useContext(Context)
    const { answers } = context.state
    const [showPreviousAnswers, setShowPreviousAnswers] = React.useState(forceShow)

    if (!showPreviousAnswers && !forceShow) {
        return (
            <Button onClick={() => setShowPreviousAnswers(true)}>Show previous answers</Button>
        )
    }

    var previousPages = context.getPageList()

    if (!previousPages.length) return <></>

    return (
        <div className="previous-answers">
            {!forceShow ? <Button onClick={() => setShowPreviousAnswers(false)}>Hide previous answers</Button> : null}

            <div style={{display: "grid", gap: "20px", margin: "10px 0"}}>
                {previousPages?.map((page: IPage, i) => {

                    return (
                        <div key={i} style={{display: "grid"}}>
                            <h5>{context.formatLabel(page.title)}</h5>

                            {page.fields?.map((field: IField, i) => {
                                
                                if (context.isHidden(field)) {
                                    return <React.Fragment key={field.name}></React.Fragment>
                                }

                                var answerDisplay = context.formatLabel(answers[field.name])

                                if (field.setListKey === "countries") {
                                    var countriesDict = context.getListSet("countries")
                                    answerDisplay = countriesDict[answerDisplay]
                                } else if (field.options) {
                                    answerDisplay = field.options[answerDisplay]
                                } else if (field.type === "checkbox") {
                                    answerDisplay = answerDisplay === "true" ? "Yes" : "No"
                                }

                                return (
                                    <div key={i} style={{display: "grid", gridTemplateColumns: "49% 2% 49%", margin: "5px 0 0 14px"}}>
                                        <small>{context.formatLabel(field.label)}</small>
                                        <span></span>
                                        <small>{context.formatLabel(answerDisplay)}</small>
                                    </div>
                                )
                            })}

                            {page.table ? (
                                <div key={i} style={{display: "grid", gridTemplateColumns: "49% 2% 49%", margin: "5px 0 0 14px"}}>
                                    <small>{answers[page.table.list_name]?.length} Entries</small>
                                </div>
                            ) : null}

                            <Button onClick={() => {
                                context.goToPageByIndex(i)
                                window.scrollTo(0, 0);
                            }} className="btn btn-md link">
                                Back to this page
                            </Button>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}