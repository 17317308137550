import React from 'react'
import { ITable } from '../interfaces'
import { Button, Modal } from "@mui/material";
import { Context } from '../context';
import FieldsForm from './FormFields';
import TruncText from '../misc/TruncText';
import { Icons } from '../misc/Icons';

interface ITableProps {
    table: ITable;
    onSubmit: Function
}

export default function Table ({
    table,
    onSubmit = (values) => {}
}: ITableProps) {

    const context = React.useContext(Context)
    var valueSets = context.state.answers[table.list_name]

    const [error, setError] = React.useState(null)
    const [rows, setRows] = React.useState(valueSets ? valueSets : [])

    // If columns are not specified, derive them fields
    if (!table.columns) {
        table.columns = {}
        table.fields.forEach(field => {
            table.columns[field.name] = field.label
        })
    }

    var minWidth = `${Object.keys(table.columns).length * 150}px`
    var maxWidth = `${Object.keys(table.columns).length * 250}px`

    const addRow = (newRow) => {
        setError(null)
        setRows([...rows, newRow])
    }

    const removeRow = (index) => {
        setError(null)
        var newList = []
        rows.forEach((row, i) => {
            if (index !== i) {
                newList.push(row)
            }  
        })
        setRows(newList)
    }

    const updateRow = (index, updatedRow) => {
        var newList = []
        rows.forEach((row, i) => {
            newList.push(index === i ? updatedRow : row)
        })
        setRows(newList)
    }

    const handleSubmit = () => {
        if (table.min_rows && rows?.length < table.min_rows) {
            return setError(`You must provide at least ${table.min_rows} entr${table.min_rows > 1 ? "ies" : "y"}`)
        }

        if (table.max_rows && rows?.length > table.max_rows) {
            return setError(`You must provide no more than ${table.max_rows} entr${table.max_rows > 1 ? "ies" : "y"}`)
        }
        onSubmit({[table.list_name]: rows})
    }
    
    return (
        <div className="table-section">
            <div className="table" style={{minWidth: minWidth, maxWidth: maxWidth}}>

                <div className="table-headings">
                    {Object.values(table.columns).map((label: string, i) => (
                        <div key={i} className="table-cell">{label}</div>
                    ))}
                    <div className="table-cell"></div>
                </div>

                <div className="table-body">
                    {rows.map((row, i) => (
                        <div key={i} className="table-row">
                            {Object.keys(table.columns).map((fieldKey, i) => {
                                return (
                                    <div key={i} className="table-cell">
                                        <TruncText text={row[fieldKey]} />
                                    </div>
                                )
                            })}
                            <div className="table-cell">
                                <div style={{display: "flex", gap: "5px", marginLeft: "auto"}}>
                                    <RowFormModal 
                                        row={row} 
                                        table={table} 
                                        opener={<Button className="btn btn-xs" variant="contained">Edit</Button>}
                                        onSubmit={(updatedRow) => updateRow(i, updatedRow)} />
                                    <Button 
                                        onClick={() => removeRow(i)}
                                        className="btn btn-xs" 
                                        color="error" 
                                        variant="contained">
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}

                    {!rows.length ? (
                        <div className="table-row">
                            <div className="table-cell">
                                No entries
                            </div>
                        </div>
                    ) : null}
                </div>

                {error ? <div className="warning">{error}</div> : null}
            </div>
            

            <br/>

            <RowFormModal 
                table={table} 
                row={{}} 
                onSubmit={addRow} 
                opener={<Button  className="btn btn-sm" variant="contained">Add new {table.list_item_label}</Button>} />

            <br/>

            <Button 
                type="submit" 
                color="success"
                variant="contained"
                className="btn btn-lg" 
                disabled={context.state.locked}
                onClick={() => handleSubmit()}>
                Next
            </Button>

            {context.state.locked ? <small>This questionnaire is locked</small> : null}
        </div>
    )
}


function RowFormModal ({
    row, 
    table, 
    opener,
    onSubmit = (newRow) => {}
}) {

    const [open, setOpen] = React.useState(false)

    return (
        <>
            <span onClick={() => setOpen(true)}>
                {opener}
            </span>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <div className="modal">
                    <div className="modal-body">
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <h3>Add new {table.list_item_label ? table.list_item_label : "row"}</h3>
                            <Button className="btn btn-xs" color="info" onClick={() => setOpen(false)}><Icons.X />Close</Button>
                        </div>
                        
                        <FieldsForm maxHeight="70vh" initialValues={row} fields={table.fields} onSubmit={(values) => {
                            var newRow = {}
                            table.fields.forEach(field => {
                                newRow[field.name] = values[field.name]
                            })
                            onSubmit(newRow)
                            setOpen(false)
                        }} />
                    </div>
                </div>
            </Modal>
        </>
    )
}