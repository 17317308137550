import React from "react"
import Logo from "../misc/Logo.tsx"
import { Context } from "../context"
import { Button, LinearProgress } from "@mui/material"
import Questionnaire from "./Questionnaire.tsx"
import { Route, Routes, useParams, useNavigate } from "react-router-dom"


export default function App() {

	const context = React.useContext(Context)

	React.useEffect(() => {
		window.onkeydown = (e) => {
			console.log(e)
			if (e.ctrlKey && e.shiftKey && e.key === "Q") {
				context.toggleDevTools()
			}
		}
	}, [])

	return (
		<Routes>
			<Route path="/" element={<FallbackPage />} />
			<Route path="/:advisorUid/*" element={(
				<AdvisorKnown>
					<Routes>
						<Route path="/" exact element={<h3>No questionnaire</h3>} />
						<Route path="/:questionnaireUid" element={<Questionnaire />} />
						{/* <Route path="/:questionnaireUid" element={<h1>Questionnaire</h1>} /> */}
						{/* <Route path="/init/:instanceId/:accountUid/:questionnaireName" exact element={<QuestionnaireCreate />} /> */}
					</Routes>
				</AdvisorKnown>
			)} />
		</Routes>
	)
}


function AdvisorKnown (props) {

	var params = useParams()
	var navigate = useNavigate()
	const context = React.useContext(Context)

	React.useEffect(() => {
		context.getAdvisorAccount(params.advisorUid).catch(() => {
			navigate("/")
		})
	}, [])

	if (!context.state.advisorAccount) return <LinearProgress />
	
	return (
		<div className="app">
			<header>
				<div className="main-inner">
					{context.state.advisorAccount.logoUri 
						? <img src={context.state.advisorAccount.logoUri} alt="logo" height="40" />
						: <Logo width={100} type="block" />}

					<div style={{display: "flex", gap: "10px"}}>
						{context.isLoggedIn() ? (
							<Button 
								className="btn btn-sm" 
								onClick={() => {
									window.localStorage.removeItem("aora_questionnaire_jwt")
									window.location.reload()
								}}>
								Log out
							</Button>
						) : null}
					</div>
				</div>
			</header>
			
			<main>
				<div className="main-inner">
					{props.children}
				</div>
			</main>
		</div>
	)
}


function FallbackPage () {
	return (
		<div className="main-inner" style={{height: "100vh", paddingTop: "20vh"}}>
			<h1 style={{fontSize: "8vw", fontWeight: "800", lineHeight: "8vw"}}>Data Driven Questionnaires</h1>
			<h1 style={{fontWeight: "600", marginTop: "15px"}}>by <Logo width={80} type="block" /></h1>
			<p>Keeping data-entry to a minimum</p>
		</div>
	)
}