import React from 'react'
import { IField } from '../interfaces'
import { FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import { Context } from '../context';
import { isValid } from 'date-fns';

interface IFieldProps {
    field: IField;
    formik: any;
    autoFocus?: boolean;
}



export default function Field ({field, formik, autoFocus = false}: IFieldProps) {

    const context = React.useContext(Context)
    const label = context.formatLabel(field.label)

    switch (field.type) {
        case "checkbox":
            return (
                <div className="form-field field-checkbox">
                    <label>
                        <input
                            name={field.name}
                            checked={formik.values[field.name] === "true"}
                            type="checkbox"
                            onChange={(e) => {
                                formik.setFieldValue(field.name, formik.values[field.name] === "true" ? "false" : "true")
                            }}
                        />
                        <span className="field-checkbox-label-content">{label}</span>
                    </label>
                    {formik.submitCount && formik.errors[field.name] ? <span className="warning">{formik.errors[field.name]}</span> : null}
                    <span className="help-text">{field.helpText}</span>
                </div>
            )
        case "radio":

            return (
                <div className="form-field field-radio-select">
                    <FormControl>
                        {label ? <FormLabel>{label}</FormLabel> : null}
                        
                        {field.helpText ? <span className="help-text" style={{marginTop: "4px"}}>{field.helpText}</span> : null}
                        
                        <RadioGroup
                            name={field.name}
                            value={formik.values[field.name]}
                            style={{margin: "10px 0 10px 15px"}}
                            onChange={formik.handleChange} >

                            {Object.keys(field.options)?.map((key, i) => {
                                var label = context.formatLabel(field.options[key])
                                // Don't show options with placeholders remaining
                                if (label.includes("{") || label.includes("}")) {
                                    return <React.Fragment key={i}></React.Fragment>
                                }
                                
                                return (
                                    <FormControlLabel
                                        key={i}
                                        value={key}
                                        label={label}
                                        control={
                                            // Apply autoFocus to the first option
                                            <Radio inputProps={{autoFocus: i === 0 ? autoFocus : false}} />
                                        } />
                                )
                            })}
                        </RadioGroup>
                        {formik.submitCount && formik.errors[field.name] ? <span className="warning">{formik.errors[field.name]}</span> : null}
                    </FormControl>
                </div>
            )

        case "select":

            var options = field.setListKey
                ? context.getListSet(field.setListKey)
                : field.options

            return (
                <FormControl variant="standard" className="form-field">
                    <InputLabel id={field.name}>{label}</InputLabel>
                    <Select
                        labelId={field.name}
                        id={field.name}
                        name={field.name}
                        value={formik.values[field.name]}
                        autoFocus={autoFocus}
                        error={formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : false}
                        onChange={formik.handleChange}>
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {Object.keys(options).map((value, i) => {
                                var label = context.formatLabel(options[value])
                                // Don't show options with placeholders remaining
                                if (label.includes("{") || label.includes("}")) {
                                    return <React.Fragment key={i}></React.Fragment>
                                }
                                return <MenuItem key={i} value={value}>{label}</MenuItem>
                            })}
                    </Select>

                    {field.helpText ? <span className="help-text" style={{marginTop: "4px"}}>{field.helpText}</span> : null}
                    <small className="warning">{formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : null}</small>
                </FormControl>
            )
        case "date":

            var value = formik.values[field.name]

            // Support UK date string format as input. Output will always be ISO format.
            if (value?.includes("/")) {
                var split = formik.values[field.name]?.split("/")
                value = split?.reverse().join("-")
            }

            return (
                <label className="form-field field-date">
                    {label}

                    <input
                        type="date"
                        name={field.name}
                        autoFocus={autoFocus}
                        value={value}
                        onChange={(e) => {
                            // var split = e.target.value.split("-")
                            // var value = split.reverse().join("/")
                            // formik.setFieldValue(field.name, value)
                            var date = new Date(e.target.value)
                            
                            // Limit years to 4 digits
                            if (isValid(date) && date.getFullYear() > 9999) {
                                return
                            }
                            
                            formik.setFieldValue(field.name, e.target.value)
                        }} />

                    {field.helpText ? <span className="help-text" style={{marginTop: "4px"}}>{field.helpText}</span> : null}
                    <small className="warning">{formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : null}</small>

                </label>
            )

        case "number":
        case "text-area":
        default:

            var len = formik.values[field.name]?.length
            var helpText = field.helpText ? field.helpText : false

            if (field.type === "text-area") {
                helpText = `${len ? len : 0} characters ${helpText ? `\n${helpText}` : ""}`
            }

            return (
                <div className="form-field field-date" style={{display: "grid"}}>
                    <TextField
                        name={field.name}
                        value={formik.values[field.name]}
                        label={label}
                        type={field.type === "number" ? "number" : "text"}
                        variant={field.type === "text-area" ? "filled" : "standard"}
                        multiline={field.type === "text-area"}
                        rows={field.type === "text-area" ? 4 : 1}
                        autoFocus={autoFocus}
                        helperText={helpText}
                        error={formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : false}
                        onChange={formik.handleChange}/>

                    {/* {field.type === "text-area" ? <small>{len ? len : 0} characters</small> : null} */}

                    <small className="warning">{formik.submitCount && formik.errors[field.name] ? formik.errors[field.name] : null}</small>
                </div>
            )
    }
}