import React, { useEffect } from "react"
import Page from './Page.tsx'
import { Context } from '../context'
import PreviousAnswers from "./PreviousAnswers.tsx"
import { Button, CircularProgress, LinearProgress } from "@mui/material"
import { useParams } from "react-router-dom"
import DevTools from "./DevTools.tsx"
import LoginPage from "./LoginPage.tsx"


export default function Questionnaire () {

    const params = useParams()

    const context = React.useContext(Context)
    const { loadingQuestionnaire, questionnaire, submitting } = context.state

    useEffect(() => {
        if (!questionnaire) {
            context.loadQuestionnaire(params.questionnaireUid)
        }
    }, [])

    if (!window.localStorage.getItem("aora_questionnaire_jwt")) return <LoginPage />

    if (loadingQuestionnaire) return <h4>Loading questionnaire...</h4>

    if (!questionnaire) return <h4>No questionnaire</h4>

    return (
        <div className={submitting ? "questionnaire disabled" : "questionnaire"}>
            <QuestionnaireHeader />
            <QuestionnaireBody />
            <DevTools />
        </div>
    )
}


function QuestionnaireHeader () {
    
    const context = React.useContext(Context)
    const { questionnaire, pageIndex, previewPage } = context.state
    var pageList = context.getPageList()

    if (previewPage) {
        return (
            <div className="questionnaire-header">
                <Button variant="contained" className="btn btn-sm" onClick={() => context.setPreviewPage(null)}>
                    <h4>Exit page preview</h4>
                </Button>
                <small>You are previewing this page</small>
            </div>
        )
    }
        
    return (
        <div className="questionnaire-header">
            <Button
                disabled={pageIndex === 0 || pageList?.length < 1}
                className="btn btn-sm"
                variant="contained"
                onClick={() => context.backPage()}>
                <h4>Go back</h4>
            </Button>
            <small>{questionnaire.name}</small>
        </div>
    )
}


function QuestionnaireBody () {

    const context = React.useContext(Context)
    const { previewPage, submitting } = context.state

    var page = previewPage ? previewPage : context.getCurrentPage()

    if (submitting) return <CircularProgress style={{width: "18px", height: "18px", margin: "50px 100px"}} />

    if (!page) return <QuestionnaireFinalPage />
    
    return (
        <>
            <Page key={Math.random()} page={page} />
            <PreviousAnswers />
        </>
    )
}


function QuestionnaireFinalPage () {
    
    const context = React.useContext(Context)
    const { locked, submitting } = context.state

    return (
        <div className="questionnaire-page">
            <h3>Thank you for completing this questionnaire.</h3>

            {locked 
                ? <p>This questionnaire is locked.</p>
                : <p>Please check all answers before submitting. Once submitted, you will no longer be able to edit, and the information will be sent to your Legal Advisor.</p>
            }

            <PreviousAnswers forceShow={true} />

            <Button className="btn btn-lg" color="success" variant="contained" disabled={submitting || locked} onClick={() => context.submitQuestionnaire()}>
                Confirm answers and Submit
            </Button>
        </div>
    )
}


