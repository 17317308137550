import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from './misc/ErrorBoundary'
import ContextProvider from './context'
import {Theme} from './misc/Theme.ts'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import './style/App.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<BrowserRouter>
		<ErrorBoundary>
			<ThemeProvider theme={Theme}>
				<ContextProvider>
					<App />
				</ContextProvider>
			</ThemeProvider>
		</ErrorBoundary>
	</BrowserRouter>
)