import { IPage } from '../interfaces'
import Table from './Table.tsx'
import FieldsForm from './FormFields.tsx'
import React from 'react';
import { Context } from '../context.tsx';

interface IPageProps {
    page: IPage;
}

export default function Page ({page}: IPageProps) {

    const context = React.useContext(Context)
    const title = context.formatLabel(page.title)
    const description = context.formatLabel(page.description)

    return (
        <div className="questionnaire-page">
            <h2>{title}</h2>
            
            {description ? <div dangerouslySetInnerHTML={{__html: description}}></div> : null}

            {page.table ? (
                <Table 
                    table={page.table} 
                    onSubmit={(values) => {
                        context.submitPageAnswers(values)
                    }} /> 
            ) : (
                <FieldsForm 
                    submitButtonText="Next" 
                    initialValues={context.state.answers} 
                    fields={page.fields} 
                    onSubmit={(values) => {
                        context.submitPageAnswers(values)
                    }} />
            )}

            {context.state.errors?.map((error, i) => <p className="warning">{error}</p>)}
        </div>
    )
}