import React from 'react';
import Ajax from './Ajax';
import listSets from './misc/listSets.json'
// import QuestionnaireJson from './misc/Family.json';
import { IPage, IQuestionnaire } from './interfaces';

export const Context = React.createContext<any | null>(null);

interface IContextState {
    advisorAccount: any;
    questionnaireUid: string;
    questionnaire: IQuestionnaire;
    answers: any;
    locked: boolean;
    loadingQuestionnaire: boolean;
    submitting: boolean;
    listSets: any;
    pageIndex: number;
    previewPage: IPage;
    errors: [],
    showDevTools: boolean;
}

const initialState: IContextState = {
    advisorAccount: null,
    questionnaireUid: null,// "8fd9aa49-fba9-49a7-a034-39315996abc7",
    questionnaire: null,// QuestionnaireJson,
    answers: {},
    locked: false,
    loadingQuestionnaire: true,
    submitting: false,
    listSets: listSets,
    pageIndex: null, // Current page
    previewPage: null,
    errors: [],
    showDevTools: false
}

export default class ContextProvider extends React.Component<any, any> {

    state = {...initialState}

    isHidden = (fieldOrPage, values = {}) => {

        var valuesStored = {...this.state.answers, ...values}        
        var valuesToLookFor = {...fieldOrPage.dependsOn, ...fieldOrPage.dependsOnAny, ...fieldOrPage.hideDependsOn, ...fieldOrPage.hideDependsOnAny}

        var fieldsKeysSatisfied = Object.keys(valuesToLookFor).filter((key) => {
            var valueToLookFor = valuesToLookFor[key]
            return Array.isArray(valueToLookFor) ? valueToLookFor.includes(valuesStored[key]) : valueToLookFor === valuesStored[key]
        })

        if (fieldOrPage.dependsOn) {
            return Object.keys(valuesToLookFor).length !== fieldsKeysSatisfied.length
        }
        if (fieldOrPage.hideDependsOn) {
            return !(Object.keys(valuesToLookFor).length !== fieldsKeysSatisfied.length)
        }

        if (fieldOrPage.dependsOnAny) {
            return fieldsKeysSatisfied?.length === 0
        }
        if (fieldOrPage.hideDependsOnAny) {
            return !(fieldsKeysSatisfied?.length === 0)
        }

        return false
    }

    getPageList = (submitted = true): IPage[] => {
        var pageList = []

        const handlePages = (pages: IPage[]) => {
            pages.forEach(page => {
                
                var hide = this.isHidden(page)

                if (!hide) {
                    pageList.push(page)
                }

                // Check for conditional pages
                page.fields?.forEach(field => {
                    var value = this.state.answers[field.name]
                    if (field.conditional_pages) {
                        if (value in field.conditional_pages) {
                            handlePages(field.conditional_pages[value])
                        } else if ("else" in field.conditional_pages) {
                            handlePages(field.conditional_pages["else"])
                        }
                    }
                })
            })
        }

        handlePages(this.state.questionnaire.pages)

        return pageList.filter(page => ((submitted && page.submitted) || (!submitted && !page.submitted)))
    }

    getCurrentPage = (): IPage => {

        if (this.state.pageIndex !== null) {
            var pageList = this.getPageList()
            var page = pageList[this.state.pageIndex]
            return page
        }

        var unsubmittedPages = this.getPageList(false)

        if (unsubmittedPages.length) {
            return unsubmittedPages[0]
        }

        return null
    }

    // Remove any answers which belong to fields which have been conditionally hidden
    cleanAnwers = () => {
        var visibleFieldNames = []
        var pages = [...this.getPageList(), this.getCurrentPage()]
        pages.forEach((page) => {
            if (page?.table?.list_name) {
                visibleFieldNames.push(page.table?.list_name)
            }
            page?.fields?.forEach(field => {
                if (!this.isHidden(field, this.state.answers)) {
                    visibleFieldNames.push(field.name)
                }
            })
        })

        var answers = {...this.state.answers}

        Object.keys(answers).forEach(key => {
            if (!visibleFieldNames.includes(key)) {
                delete answers[key]
            }
        })

        this.setState({answers: answers})
    }

    // If page is hidden, set submitted to false so that if it later becomes unhidden, it will be the next page shown
    cleanPages = () => {
        var visiblePages = [...this.getPageList(), this.getCurrentPage()]

        const handlePages = (pages: IPage[]) => {
            pages.forEach(page => {

                if (!visiblePages.includes(page)) {
                    page.submitted = false
                }

                // Check for conditional pages
                page.fields?.forEach(field => {
                    if (field.conditional_pages) {
                        Object.keys(field.conditional_pages).forEach(key => handlePages(field.conditional_pages[key]))
                    }
                })
            })
        }
        var questionnaire = {...this.state.questionnaire}
        handlePages(questionnaire.pages)
        this.setState({questionnaire: questionnaire})
    }

    addAnswers = (answers) => {
        console.log("Adding answers", answers)

        var allAnswers = {...this.state.answers, ...answers}
        this.setState({answers: allAnswers}, () => {
            this.cleanAnwers()
            this.cleanPages()
        })
        return allAnswers
    }

    submitPageAnswers = async (answers) => {

        var allAnswers = this.addAnswers(answers)

        this.setState({submitting: true, errors: []})
        var page = this.getCurrentPage()
        page.submitted = true

        await Ajax.Questionnaire.Update({
            uid: this.state.questionnaireUid,
            answers: JSON.stringify(allAnswers),
            json: JSON.stringify(this.state.questionnaire)
        }).then((response) => {
            this.setState({questionnaire: this.state.questionnaire, pageIndex: null})
            window.scrollTo(0, 0)
        }).catch((response) => {
            delete page.submitted
            this.setState({questionnaire: this.state.questionnaire, errors: ["Something went wrong"]})
        })

        this.setState({submitting: false})
    }

    submitQuestionnaire = async () => {
        this.setState({submitting: true})
        
        await Ajax.Questionnaire.Submit(this.state.questionnaireUid).then(() => {
            this.setState({locked: true})
        }).catch(() => {
            this.setState({errors: ["Something went wrong"]})
        })
        
        this.setState({submitting: false})
    }

    overwriteQuestionnaire = async (QuestionnaireJson: string) => {
        await Ajax.Questionnaire.Update({
            uid: this.state.questionnaireUid,
            answers: "{}",
            json: JSON.stringify(JSON.parse(QuestionnaireJson))
        }).then((response) => {
            window.location.reload()
        }).catch((response) => {
            console.log(response)
        })
    }

    loadQuestionnaire = async (id) => {

        this.setState({loadingQuestionnaire: true})

        await Ajax.Questionnaire.Get(id).then((response) => {
            this.setState({
                questionnaireUid: response.uid,
                questionnaire: JSON.parse(response.json),
                answers: response.answers ? JSON.parse(response.answers) : {},
                locked: response.locked
            })
            document.title = response.template.name
        }).catch((response) => {
            this.setState({errors: ["Something went wrong"]})
        })
        
        this.setState({loadingQuestionnaire: false})
    }

    getListSet = (setKey) => {
        if (Object.keys(this.state.listSets).includes(setKey)) {

            var sortedListSet = {}
            var listSet = this.state.listSets[setKey]

            var items = Object.keys(listSet).map(function(key) {
                return [key, listSet[key]];
            })

            // Sort the array based on the second element
            items.sort((a, b) => a[1].localeCompare(b[1]))

            items.forEach((item) => {
                sortedListSet[item[0]] = item[1]
            })

            return sortedListSet
        }

        return {}
    }

    formatLabel = (message) => {
        if (message) {
            var placeholders = message.match(/{[a-zA-Z\-_0-9]*}/g, message)
            if (placeholders && Array.isArray(placeholders)) {
                for (var i = 0; i < placeholders.length; i++) {
                    var placeholder = placeholders[i]
                    var attribute_key = placeholder.replace("{", "").replace("}", "")
                    var value = this.state.answers[attribute_key]
                    if (value) {
                        message = message.replace(`${placeholder}`, value)
                    }
                }
            }
        }
        return message
    }

    backPage = () => {
        if (this.state.pageIndex === null) {
            var pageList = this.getPageList()
            this.setState({pageIndex: pageList.length - 1})
        } else {
            var newIndex = this.state.pageIndex > 1 ? this.state.pageIndex - 1 : 0
            this.setState({pageIndex: newIndex})
        }
    }

    goToPageByIndex = (index) => {
        this.setState({pageIndex: index})
    }
    
    isLoggedIn = () => {
        return !!window.localStorage.getItem("aora_questionnaire_jwt")
    }

    setPreviewPage = (page: IPage) => {
        this.setState({previewPage: page})
    }

    reset = () => {
        this.setState(initialState)
    }

    getAdvisorAccount = async (advisorUid: string) => {
        await Ajax.Public.GetAdvisorAccount(advisorUid).then(response => {
            console.log("Got advisor account", response)
            this.setState({advisorAccount: response})
        })
    }

    toggleDevTools = () => {
        this.setState({showDevTools: !this.state.showDevTools})
    }

    render () {
        return (
            <Context.Provider value={{...this}}>
                {this.props.children}
            </Context.Provider>
        )
    }
}



export const getURLPeram = (url, name) => {

    if (url && url.split("?").length > 1) {
        var query_string = url.split("?")[1]
        var perams = query_string.split("&")

        for (let i = 0; i < perams.length; i++) {
            if (perams[i].split("=")[0] === name && perams[i].split("=").length > 1) {
                return perams[i].split("=")[1]
            }
        }
    }
    return null
}

export const getCookie = (cname) => {

    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}